<template>
  <div class="favorite">
    <div class="title">{{ $t("User.Account.Pages.Wishlist") }}</div>

    <div class="list" ref="list">
      <div class="item" v-for="item in favList" :key="item.goods_id">
        <div class="top">
          <div class="img">
            <img
              v-lazy="item.image"
              alt=""
              @click="goGoodDetil(item.product_id)"
            />
          </div>
          <div class="name font_hide" @click="goGoodDetil(item.product_id)">
            {{ item.name }}
          </div>
          <div class="price font_hide">{{ item.price_text }}</div>
        </div>
        <div class="bottom">
          <div class="addCart btn" @click="add(item)">
            <i class="iconfont icon-jiarugouwuche"></i
            ><span>{{ $t("Goods.Pages.AddToCart") }}</span>
          </div>
          <div class="remove btn" @click="cancelLike(item)">
            <i class="iconfont icon-shanchu"></i
            ><span>{{ $t("Goods.Pages.RemoveGood") }}</span>
          </div>
        </div>
      </div>
    </div>

    <empty
      :decripe="$t('Member.Common.NoFav')"
      v-if="paginated.limit && favList.length == 0"
    ></empty>

    <!-- 分页 -->
    <div class="pagination">
      <pagination
        :totalPage="paginated.limit ? paginated.pages : 1"
        v-model="currentPage"
        @changePage="changePage"
      ></pagination>
    </div>
  </div>
</template>
<script>
import User from "@/model/User.js";
import Pagination from "common/pagination/Pagination";
import Cart from "@/model/Cart";
import Goods from "@/model/Goods";
import Empty from "common/empty/Empty";
export default {
  name: "MemberFavorite",
  data() {
    return {
      favList: [],
      paginated: {},

      currentPage: 1,
    };
  },
  created() {
    this.getFavList();
  },
  methods: {
    add(item) {
      Cart.Add({
        data: {
          goods_id: item.goods_id,
          product_id: item.product_id,
          num: 1,
          type: "goods",
        },
        succ: (res, all) => {
          this.$store.commit("updateCartData", res);
          this.$message("addCart", all.msg);
          // 真实的购物车数据
          // let cartList = res.carts;
          // let self_cart_ident =
          //   "goods_" + item.goods_id + "_" + item.product_id;

          // this.$cart_add(cartList, self_cart_ident);
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
      });
    },

    //跳转到商品详情页
    goGoodDetil(product_id) {
      this.$router.push({
        path: "/product/index",
        query: {
          product_id,
        },
      });
    },

    //取消收藏
    cancelLike(item) {
      this.$confirm(
        "danger",
        this.$t("Common.Confirm.Title"),
        this.$t("Member.Msg.RemoveFav"),
        true,
        null,
        this
      )
        .then((ele) => {
          Goods.Goods.Fav({
            data: {
              goods_id: item.goods_id,
            },
            succ: (res, all) => {
              this.$message("setLike", all.msg);
              ele.close();
              // 如果当前页只有一个商品则取消收藏后 currentPage需要减一
              if (this.favList.length == 1) {
                if (this.currentPage > 1) {
                  this.currentPage--;
                } else {
                  this.currentPage = 1;
                }
              }
              this.getFavList();
            },
            fail: (res, all) => {
              ele.close();
              this.$message("error", all.msg);
            },
            
          });
        })
        .catch(() => {});
    },

    getFavList() {
      let loading;
      this.$nextTick(() => {
        loading = this.$loading(this.$refs.list);
      });
      User.Fav.List({
        data: {
          page: this.currentPage,
          limit: 8,
          type: "goods",
        },

        succ: (res) => {
          loading.close();
          this.favList = res.fav_list;
          this.paginated = res.paginated;
        },
        fail: (res, all) => {
          loading.close();
          this.$message("error", all.msg);
        },
        
      });
    },
    //切换页码
    changePage() {
      this.getFavList();
    },
  },

  components: {
    Pagination,
    Empty,
  },
};
</script>
<style lang='scss' scoped>

.favorite {
  background-color: white;
  padding: 1rem;
  .title {
    margin-bottom: 0.75rem;
    font-size: 1rem;
    letter-spacing: 1px;
    font-weight: 600;
    // color:white;
    // width: 8.5rem;
    // text-align: center;
    padding: 0.5rem 0;
    border-radius: 0.2rem;
    // background-color:var(--red-color);
    color: var(--text-color-title);
  }

  .list {
    min-height: 6rem;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .item {
      margin-right: 1%;
      margin-left: 1%;
      margin-bottom: 2%;
      width: 23%;
      box-sizing: border-box;
      border: 1px solid var(--border-color-default);
      .top {
        padding: 1rem 1.5rem;
        .img {
          width: 100%;
          padding-bottom: 100%;
          position: relative;
          cursor: pointer;
          img {
            position: absolute;
            height: 100%;
            width: 100%;
          }
        }
        .name {
          margin-top: 1rem;
          font-size: 0.75rem;
          color: var(--text-color-default);
          text-align: center;
          cursor: pointer;
          &:hover {
            color: var(--red-color);
          }
        }
        .price {
          font-size: 0.75rem;
          font-weight: 600;
          color: var(--text-color-title);
          text-align: center;
          margin: 0.5rem 0 0 0;
        }
      }

      .bottom {
        display: flex;
        .btn {
          width: 50%;
          border-top: 1px solid var(--border-color-default);
          box-sizing: border-box;
          background-color: #f9f9f9;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.75rem;
          color: var(--text-color-default);
          cursor: pointer;
          i {
            margin-right: 0.2rem;
          }

          &:hover {
            color: var(--red-color);
          }
        }

        .addCart {
          border-right: 1px solid var(--border-color-default);
        }
      }
    }
  }
}

.pagination {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
</style>